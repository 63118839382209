<template>
  <v-container>
    <v-textarea v-model="input"
                outlined
                rows="8">
    </v-textarea>
    <v-btn color="primary"
           large
           @click="convert">
      Convert
    </v-btn>
    <div>
      {{ json }}
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'JsonRaw',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      input: null,
      json: 'eredmény',
    };
  },
  methods: {
    convert() {
      this.json = JSON.stringify(this.input);
    },
  },
};
</script>

<style scoped>

</style>
